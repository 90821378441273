import axiosInstance from "@/axiosInstance";

export default {
    namespaced: true,

    actions: {
        async add({ dispatch }, payload) {
            await axiosInstance.post('subscription/add', {payload}).then((response) => {
                console.log(dispatch);
                if (response.data) {
                    return true;
                } else {
                    return false;
                }
            });
        }
    }
}
