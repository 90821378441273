<template>
  <div class="menu-block" v-for="(menuCategory, index) in menuElements" :key="index">
    <span v-if="showTitles" class="menu-category-title">{{ menuCategory.name }}</span>
    <template v-for="(element, elIndex) in menuCategory.elements" :key="elIndex">
      <template v-if="element.link_type === 'router'">
        <router-link :to="element.url" :target="(element.target === 'blank' ? '_blank' : '_self')">
          <span>{{ element.name }}</span>
        </router-link>
      </template>
      <template v-else>
        <a :href="element.url" :target="( element.target === 'blank' ? '_blank' : '_self' )">
          <span>{{ element.name }}</span>
        </a>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MenuColumns',
  props: {
    menuElements: {
      type: Object,
    },
    showTitles: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss" scoped>
  .menu-block {
    width: 25%;

    span {
      &.menu-category-title {
        display: block;

        padding-bottom: 1rem;

        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    a {
      display: block;

      color: var(--second-href-color);
      font-size: 16px;

      margin-bottom: 10px;
    }
  }
</style>
