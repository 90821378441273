<template>
  <footer>
    <div class="footer-block">
      <span>
        <i class="far fa-copyright" aria-hidden="true"></i> <a href="https://keyntr.ru" rel="first" title="Сайт KeynTR">KeynTR.ru</a> 2022 г.
      </span>
      <span>
        Автор и разработчик: <a class="js_show_feedback_form" href="mailto:keyn-artur@yandex.ru" title="Написать письмо администратору сайта" @click.prevent="createFeedbackModal()">KeynTR</a>
      </span>
    </div>
    <MenuByTypes
        :arColumnsTypes="arColumnsTypes"
    />
    <div class="footer-icon-block">
      <router-link to="/site_map" class="site-map"><i class="fas fa-map with-hover-v-2"></i></router-link>
    </div>
    <FeedbackForm
        ref="feedback"
        @createModal=createModal
        v-show="false"
    />
  </footer>
</template>

<script>
import FeedbackForm from "@/components/forms/FeedbackForm";
import agentMixin from "@/mixins/agentMixin";
import MenuByTypes from "@/components/menu/MenuByTypes";

export default {
  name: 'Footer',
  mixins: [ agentMixin ],
  components: { FeedbackForm, MenuByTypes },
  data() {
    return {
      arColumnsTypes: ['footer_column_1', 'footer_column_2', 'footer_column_3']
    }
  },
  methods: {
    createFeedbackModal() {
      this.$refs.feedback.init();
    },
  }
}
</script>

<style lang="scss" scoped>
  footer {
    display: flex;
    font-size: 18px;
    padding: 25px 60px;
    color: var(--second-block-color);
    background-color: var(--main-block-color);

    a {
      color: var(--second-text-color);
    }

    .footer-block {
      position: relative;
      width: 25%;

      span {
        display: block;
        line-height: 30px;
      }
    }

    .footer-icon-block {
      position: relative;
      width: 0;

      .site-map {
        position: absolute;
        right: 0;
        top: calc(50% - 15px);
        font-size: 35px;

        i {
          transition: 0.2s;
        }
      }
    }
  }

  @media (max-width: 768px) {
    footer {
      display: block;
      text-align: center;
      padding: 25px 10px;

      .footer-block {
        width: 100%;
        margin-bottom: 10px;

        .site-map {
          display: block;
          position: relative;
          margin-top: 15px;
        }
      }
    }
  }
</style>
