<template>
    <div class="js_feedback_form">
      <form class="feedback-form" id="feedback_form">
        <h2>Написать автору сайта</h2>
        <div class="error_block"></div>
        <label>
          <span>Ваше имя</span>
          <input type="text" name="name">
        </label>
        <label>
          <span>e-mail</span>
          <input type="text" name="email">
        </label>
        <label>
          <span>Телефон</span>
          <input type="text" name="phone">
        </label>
        <label>
          <span>Сообщение</span>
          <textarea name="message"></textarea>
        </label>
        <div id="feedback_captcha" class="google_recaptcha_block"></div>
        <button type="submit">Отправить</button>
      </form>
      <div class="success_box">
        Ваше сообщение отправлено!
        <button class="js_more_message">Написать ещё</button>
        <button class="js_close_modal">Закрыть окно</button>
      </div>
    </div>
</template>

<script>
export default {
  name: 'FeedbackForm',
  methods: {
    init() {
      this.$emit(
          'createModal',
          document.querySelector('.js_feedback_form'),
          {
            id: 'feedback',
            size: 'small'
          }
        );
    }
  }
}
</script>
