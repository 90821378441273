<template>
  <MenuColumns
      :menuElements="menuElements"
      :showTitles="showTitles"
  >
  </MenuColumns>
</template>

<script>
import axiosInstance from "@/axiosInstance";
import MenuColumns from "@/components/menu/MenuColumns";

export default {
  name: 'MenuByTypes',
  components: {MenuColumns},
  props: {
    menuType: {
      type: String,
      default: null,
    },
    showTitles: {
      type: Boolean,
      default: false,
    },
    arColumnsTypes: { // Типы колонок, забираемых к бекенда
      default: [
        "footer_column_1",
        "footer_column_2",
        "footer_column_3",
        "footer_column_4"
      ]
    },
  },
  data() {
    return {
      menuElements: null,
    }
  },
  created() {
    switch (this.menuType) {
      case 'articleMenu':
        this.getArticlesMenu();
        break;
      default:
        this.getMenu();
        break;
    }
  },
  methods: {
    async getMenu() {
      try {
         await axiosInstance
             .post(
             `menu/get`,
             { arTypes: this.arColumnsTypes }
             ).then((response) => {
                this.menuElements = response.data.data ? response.data.data : null;
             });
      } catch (e) {
        console.log(e);
      }
    },
    async getArticlesMenu() {
      try {
        await axiosInstance
          .post(
              'menu/getArticlesMenu'
          ).then((response) => {
            this.menuElements = response.data.data ? response.data.data : null;
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
}
</script>
