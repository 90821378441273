<template>
  <div v-html="svgCode"></div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    iconName: String,
    stroke: {
      type: String,
      default: '#015278'
    },
    size: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: '35'
    },
    height: {
      type: String,
      default: '35'
    },
    strokeWidth: {
      type: String,
      default: '1.5'
    },
  },

  data() {
    return {
      svgCode: '',
    }
  },

  methods: {
    async getSvgCode() {
      await axios.get(`/assets/images/system_files/svg/${this.iconName}.svg`).then((response) => {

        this.svgCode = response.data
            .replace(`width="48"`, `width="${this.size ? this.size : this.width}"`)
            .replace(`height="48"`, `height="${this.size ? this.size : this.height}"`)
            .replace(`stroke-width="1.5"`, `stroke-width="${this.strokeWidth}"`)
            .replace(`stroke="#2c3e50"`, `stroke="${this.stroke}"`);
      });
    },
  },

  mounted() {
    this.getSvgCode();
  }
}
</script>
