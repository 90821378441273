<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'ModalWindows',
  data() {
    return {
      modalParentBlockSelector: '.modals-block',
    }
  },
  methods: {
    createModal (content, params = {}) {

      let modalsBlock     = document.querySelector(this.modalParentBlockSelector),
          allModalWindows = null,
          modalHtml       = '';

      // размер модального окна
      !params.size ? params.size = 'huge' : '';

      // идентификатор модального окна
      if (params.id === undefined) {

        allModalWindows = modalsBlock ? modalsBlock.querySelectorAll('.modal-window') : null;

        if (allModalWindows && allModalWindows.length > 0) {

          params.id = `modal-${allModalWindows.length}`;

          if (modalsBlock.querySelector(`#${params.id}`))
            params.id = `modal-${allModalWindows.length + 1}`;
        } else {
          params.id = `modal-0`;
        }
      }

      if (typeof content === 'object')
        content = content.innerHTML;

      modalHtml += `
        <div id="${params.id}" class="modal-window ${params.size}" data-dont_close_param="${params.dontClose === true}">
          ${params.dontClose === true ? '' : '<i class="fas fa-times js_close_modal"></i>'}
            <div class="modal-content-box">
              ${content}
            </div>
        </div>
      `;


      if (modalsBlock) {
        if (modalsBlock.querySelector(`#${params.id}`)) {

          modalsBlock.querySelector(`#${params.id}`).style.display = 'block';
          this.actionWithOverlay('show');
        } else {

          modalsBlock.insertAdjacentHTML('beforeend', modalHtml);

          this.calcHeight(params.id);

          this.actionWithOverlay('show');
          document.getElementById(params.id).style.display = 'block';

          document.querySelector(`#${params.id} .js_close_modal`).addEventListener('click', () => this.closeModal(event));
        }
      } else {
        let body = document.querySelector('body')

        let overlay = document.createElement('div');
        overlay.className = 'overlay';

        modalsBlock = document.createElement('div');
        modalsBlock.className = 'modals-block';

        body.append(overlay);
        body.append(modalsBlock);
        modalsBlock.insertAdjacentHTML('beforeend', modalHtml);

        this.calcHeight(params.id);

        overlay.style.display = 'block';
        document.getElementById(params.id).style.display = 'block';

        overlay.addEventListener('click', this.closeModal);
        document.querySelector(`#${params.id} .js_close_modal`).addEventListener('click', () => this.closeModal(event));
      }

      params.positionModeling ? this.positionModeling(60000, params.id) : '';
    },

    calcHeight(id) {
      /*
        TODO Данная функция не учитывает используемый шрифт, считает высоту так как будто сейчас используется стандартный шрифт, возникает погрешность
      */

      let elem = document.getElementById(id);

      if (elem.offsetHeight === 0) {
        this.positionModeling(10, id);
      } else {
        elem.style.marginTop = `-${elem.offsetHeight / 2}px`;
      }
    },

    closeModal(event, params = {}) {
      if (event.target.classList.contains('overlay')) {

        let allModals = document.querySelectorAll('.modal-window');

        for (let modal of allModals) {
          if (modal.style.display === 'block') {
            if (modal.getAttribute('data-dont_close_param') === 'false' || params.ignoreCheck == true) {
              modal.style.display = 'none'
              this.actionWithOverlay('hide');
            }
          }
        }
      } else {

        let currentModal = event.target.parentNode;

        if (currentModal.getAttribute('data-dont_close_param') === 'false' || params.ignoreCheck == true) {
          currentModal.style.display = 'none';
          this.actionWithOverlay('hide');
        }
      }
    },

    actionWithOverlay(action) {
      switch (action) {
        case 'show':
          document.querySelector('.overlay').style.display = 'block';
          break;

        case 'hide':
          document.querySelector('.overlay').style.display = 'none';
          break;
      }
    },

    positionModeling : function(time, id, timeout = 1) {

      time = time ? time : 5000;

      let i	= 0,
          leadTime	= 0,
          checkModalHeight = setInterval(
              () => {
              i++;
              leadTime = timeout * i;
              this.calcHeight(id);
              time <= leadTime ? clearInterval(checkModalHeight) : '';
            }, timeout
          );
    },
  },

  beforeUnmount() {
    document.querySelector('.overlay').addEventListener('click', this.closeModal);
  }
}
</script>

<style lang="scss">
.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: default;
}

.modals-block .modal-window {
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  display: none;
  z-index: 1000;
  max-height: 100%;
}

.modals-block .modal-window.huge {
  width: 70%;
  margin-left: -35%;
}

.modals-block .modal-window.medium {
  width: 36%;
  margin-left: -18%;
}

.modals-block .modal-window.small {
  width: 18%;
  margin-left: -9%;
  //min-height: 100px;
}

.modals-block .modal-window i.fa-times:first-child {
  color: var(--second-text-color);
  position: absolute;
  font-size: 34px;
  top: 0;
  right: 15px;
  cursor: pointer;
  transition: 1s;
}

.modals-block .modal-window i.fa-times:first-child:hover {
  color: var(--second-hover-color);
}

.modals-block .modal-window .modal-content-box {
  padding: 8px 45px 10px 35px;
  height: 100%;
  overflow: auto;
  margin-right: 60px;
  background: var(--main-bg-color);
}

.modal-content-box h1 {
  font-size: 24px;
  border-bottom: 1px solid #c5c5c5;
  margin-bottom: 15px;
  line-height: 50px;
}

.modal-content-box textarea {
  max-height: 100px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .modals-block .modal-window,
  .modals-block .modal-window.huge,
  .modals-block .modal-window.medium,
  .modals-block .modal-window.small {
    width: 100%;
    left: 0;
    margin-left: 0;
  }
}
</style>
