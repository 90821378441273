<template>
  <div class="side-fixed-menu">
    <template v-for="(menuElement, index) in menu" :key="index">
      <template v-if="menuElement.type === 'externalLink'">
        <a :href="menuElement.href" :target="(menuElement.target === 'blank' ? '_blank' : '_self')">
          <SvgIcons
              :icon-name="menuElement.iconName"
              :width="iconWidth"
              :height="iconHeight"
              :strokeWidth="strokeWidth"
              :class="blockClass"
          />
        </a>
      </template>
      <template v-else>
        <router-link :to="menuElement.href" :target="(menuElement.target === 'blank' ? '_blank' : '_self')">
          <SvgIcons
              :icon-name="menuElement.iconName"
              :width="iconWidth"
              :height="iconHeight"
              :strokeWidth="strokeWidth"
              :class="blockClass"
          />
        </router-link>
      </template>
    </template>
  </div>
</template>

<script>
import SvgIcons from "@/components/icons/SvgIcons";
export default {
  name: 'SideFixedMenu',

  components: {
    SvgIcons
  },

  data() {
    return {
      iconWidth: '50',
      iconHeight: '50',
      strokeWidth: '1.6',
      blockClass: 'svg-block',
      menu: [
        {
          iconName: 'brand-youtube',
          href: 'http://www.youtube.com/KeynTR',
          target: 'blank',
          type: 'externalLink',
        },
        {
          iconName: 'brand-twitch',
          href: 'https://www.twitch.tv/keyntr',
          target: 'blank',
          type: 'externalLink',
        },
        {
          iconName: 'brand-vk',
          href: 'https://vk.com/keyntr',
          target: 'blank',
          type: 'externalLink',
        },
        {
          iconName: 'brand-discord',
          href: 'https://discord.gg/JwAcAPtWpq',
          target: 'blank',
          type: 'externalLink',
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.side-fixed-menu {
  position: fixed;
  top: 150px;
  right: calc((100% - var(--main-block-width)) / 2 - 80px);

  width: 80px;

  text-align: center;
  padding: 10px 0;

  background: var(--main-bg-color);

  .svg-block {
    display: block;

    margin: 0 auto;

    padding-bottom: 10px;
  }
}
</style>
