<template>
  <div class="background">
    <div class="vein"></div>
  </div>
</template>

<style lang="scss" scoped>
  .background {
    position: fixed;
    top: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background: url("/assets/images/system_files/backgrounds/background_1.jpg") no-repeat;
    background-position: center;
    background-size: 100% auto;

    .vein {
      position: absolute;

      width: 100%;
      height: 100%;

      background: rgba(0, 0, 0, 0.5)
    }
  }
</style>
