import axiosInstance from "@/axiosInstance";

export default {
    namespaced: true,

    state: {
        commentList: [],
    },

    getters: {
        getCommentList: state => state.commentList,
    },

    mutations: {
        setCommentList(state, value) {
            state.commentList = value;
        },
    },

    actions: {
        async sendComment({ dispatch }, payload) {
            await axiosInstance.post('comment/add', {payload}).then((response) => {
                if (response.data) {

                    dispatch('fetchCommentList', {
                        entity:   payload.entity,
                        entityId: payload.entityId,
                    });

                    return true;
                } else {
                    return false;
                }
            });

        },

        async fetchCommentList({commit}, payload) {
            await axiosInstance.post('comment/getList', {payload}).then((response) => {
                if (response.data) {
                    commit('setCommentList', response.data);
                }
            });

            console.log(commit, payload);
        },
    },
}
