import axiosInstance from "@/axiosInstance";

export default {
    namespaced: true,

    state: {
        articleList:        [],
        currentArticle:     [],
    },

    getters: {
        getArticleList: state => state.articleList,
        getCurrentArticle: state => state.currentArticle,
    },

    mutations: {
        setArticleList(state, value) {
            state.articleList = value;
        },
        setCurrentArticle(state, value) {
            state.currentArticle = value;
        },
    },

    actions: {
        async fetchArticleList({commit}) {
            await axiosInstance.get('article/get').then((response) => {
                if (response.data) {
                    commit('setArticleList', response.data);
                }
            });
        },

        async fetchArticle({commit}, filter) {
            await axiosInstance.post('article/get', {filter}).then((response) => {
                if (response.data.data) {
                    commit('setCurrentArticle', response.data.data);
                }
            });
        }
    },
}
