<template>
  <form @submit.prevent="submitHandler" :class="theme">
    <input
        type="text"
        placeholder="Введите текст для поиска"
        class="button-height"
        :class="inputClass"
        v-model="searchText"
    />
    <button
        type="submit"
        :class="buttonClass"
    >
      Искать
    </button>
  </form>
</template>

<script>
export default {
  name: 'SearchLine',
  props: {
    theme: {
      type: String,
      default: 'blue',
    },
    closeBurger: {
      type: Boolean,
      required: false,
      default: () => (false),
    },
    searchQuery: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchText: '',
    };
  },
  watch: {
    searchQuery(query) {
      this.searchText = query;
    }
  },
  computed: {
    inputClass() {
      let inputClass = '';

      switch (this.theme) {
        case 'white':
          inputClass = 'border-bottom-1px-solid-second-color';
      }

      return inputClass;
    },
    buttonClass() {
      let buttonClass = '';

      switch (this.theme) {
        case 'blue':
          buttonClass = 'dark-with-border';
      }

      return buttonClass;
    }
  },
  methods: {
    submitHandler() {
      this.$router.push(`/search/?q=${this.searchText}`);

      this.closeBurger ? this.$emit('hideMenu', false) : null;
    },
    setSearchText(value) {
      this.searchText = value;
    }
  },
}
</script>

<style lang="scss" scoped>
  form {
    margin: -15px 15px 15px 0;

    input, button {
      display: inline-block;
    }

    input {
      width: calc(100% - 330px - 15px - 4px);
    }
  }

  form.white {
    input.border-bottom-1px-solid-second-color {
      border-bottom: 1px solid var(--second-border-color);
    }
  }
</style>
