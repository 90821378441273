import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/site_map',
    name: 'SiteMap',
    component: () => import('../views/SiteMap.vue')
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import('../views/ArticleList.vue')
  },
  {
    path: '/article/:slug',
    name: 'ArticleDetail',
    component: () => import('../views/NewsDetail.vue')
  },
  {
    path: '/news/:slug',
    name: 'NewsDetail',
    component: () => import('../views/NewsDetail.vue')
  },
  {
    path: '/programs/:slug',
    name: 'ProgramDetail',
    component: () => import('../views/ProgramDetail.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('../views/Events.vue')
  },
  {
    path: '/3D-scene',
    name: '3D-scene',
    component: () => import('../views/3D-scene.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue')
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(''),
  routes
})

export default router

