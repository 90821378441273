import { createStore, createLogger } from 'vuex'

import Articles from './modules/Articles'
import Alert from './modules/Alert'
import Comments from './modules/Comments'
import Menu from './modules/Menu'
import Subscription from './modules/Subscription'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        Articles,
        Alert,
        Comments,
        Menu,
        Subscription
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})
