<template>
  <header>
    <div class="float-header" :class="headType">
      <div class="wrapper">
        <div class="left-block">
          <router-link to="/" class="main-page-link">
            <span class="menu-element">Главная страница</span>
            <i class="fas fa-home menu-element-mobile menu-button"></i>
          </router-link>
          <router-link to="/about/" class="hide-on-desktop">
            <i class="fas fa-info-circle menu-element-mobile menu-button"></i>
          </router-link>
        </div>
        <div class="right-block">
          <router-link to="/about/" class="hide-on-mobile">
            <span class="menu-element">о сайте</span>
          </router-link>
          <i class="fas fa-bars menu-button" @click="showBurger = !showBurger"/>
        </div>
      </div>
    </div>
  </header>
  <BurgerMenu
      v-if="showBurger"
      @hideMenu="showHideMenu"
  />
</template>

<script>
import BurgerMenu from "@/components/menu/BurgerMenu.vue";

export default {
  name: 'FloatHeader',

  components: {
    BurgerMenu,
  },

  data() {
    return {
      showBurger: false,
      headType: 'part-of-page-head',
    }
  },

  methods: {
    showHideMenu(showMenu) {
      this.showBurger = showMenu;
    },

    scroll() {
      this.headType = window.scrollY === 0 ? 'part-of-page-head' : 'fixed-head';
    }
  },

  mounted() {
    window.addEventListener("scroll", (event) => { this.scroll(event); });
  },

  beforeUnmount() {
    window.removeEventListener("scroll", (event) => { this.scroll(event); });
  }
}
</script>

<style lang="scss" scoped>
header {
  height: 80px;

  .float-header {
    .wrapper {
      display: flex;
      width: var(--main-block-width);
      background: var(--main-bg-color);

      .menu-element {
        display: block;

        margin-right: 20px;

        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        color: var(--third-text-color);

        cursor: pointer;
      }

      .menu-element-mobile {
        display: none;
        margin-right: 8px;
      }
    }

    .left-block, .right-block {
      padding: 25px 60px;
      display: flex;
    }

    .left-block {
      width: 50%;
    }

    .right-block {
      width: 50%;
      justify-content: right;
    }

    .menu-button {
      font-size: 30px;
      color: var(--third-text-color);
      cursor: pointer;
    }

    &.fixed-head {
      position: fixed;
      top: 0;
      z-index: 500;
    }
  }
}

@media (max-width: 768px) {
  header {
    height: unset;

    .float-header {
      .wrapper {
        max-width: 100%;

        .left-block, .right-block {
          padding: 10px 10px;
        }

        .menu-element {
          display: none;
        }

        .menu-element-mobile {
          display: block;
        }
      }

      &.fixed-head {
        position: unset;
      }
    }
  }
}
</style>
