<template>
  <Background/>
  <div class="main">
    <FloatHeader/>
    <SideFixedMenu/>

    <article>
      <router-view/>
    </article>

    <Footer @createModal="createModal"/>

    <Alert/>
    <ModalWindows ref="modals"/>

  </div>
</template>

<script>
import Background from "@/components/layouts/Background.vue";
import FloatHeader from "@/components/layouts/FloatHeader.vue";
import SideFixedMenu from "@/components/menu/SideFixedMenu";

import Footer from "@/components/layouts/Footer.vue";
import Alert from "@/components/Alert.vue";
import ModalWindows from "@/components/modals/ModalWindows";
// import GoUp from "@/components/GoUp";


// import Mod from "@/components/modals/Mod";
// import InDeveloping from "@/components/maintenance/InDeveloping.vue";

export default {
  name: 'App',
  components: {
    Background,
    FloatHeader,
    SideFixedMenu,

    Footer,
    Alert,
    // InDeveloping,
    ModalWindows,
    // GoUp,
  },
  data() {
    return {
      article: '',
    }
  },
  methods: {
    createModal(content, params) {
      content ? this.$refs.modals.createModal(content, params) : '';
    },
  },
  mounted() {
    // this.$refs.modals.createModal('test modal window');
    // document.querySelector('.testrrrr').addEventListener('click', () => { alert('76867867'); });
  }
}
</script>

<style>
  @import '../public/assets/css/style.css';
  @import '../public/assets/css/adaptive_style.css';
</style>
