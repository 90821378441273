import axiosInstance from "@/axiosInstance";

export default {
    namespaced: true,
    state: {
        footerMenu: null,
        articlesMenu: null,
    },
    getters: {
        getFooterMenu: state => state.footerMenu,
        getArticlesMenu: state => state.articlesMenu,
    },
    mutations: {
        setFooterMenu(state, value) {
            state.footerMenu = value;
        },
        setArticlesMenu(state, value) {
            state.articlesMenu = value;
        },
    },
    actions: {
        async fetchArticlesMenu({commit}) {
            await axiosInstance.post('menu/getArticlesMenu',).then((response) => {
                commit('setArticlesMenu', response.data.data);
                console.log(111, response.data.data);
            });
        },

        async fetchFooterMenu({commit}, payload) {
            await axiosInstance.post(
                `menu/get`,
                payload
            ).then((response) => {
                commit('setFooterMenu', response.data.data);
            });
        }
    }
}
