<template>
  <div class="alert-box" v-show="showAlertBox" :style=alertBoxStyle>
    <span class="icon left"><i class="fas fa-exclamation"></i></span>
    <span class="message">{{ getAlertMessage }}</span>
    <span class="icon right"><i class="fa fa-times" @click="hideAlertBox"></i></span>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  data() {
    return {
      showAlertBox: false,
      alertBoxStyle: {},
    }
  },
  computed: {
    ...mapGetters({
      getAlertMessage:  'Alert/getAlertMessage',
      getAlertTime:     'Alert/getAlertTime',
      getAlertColor:    'Alert/getAlertColor',
    }),
  },
  watch: {
    getAlertMessage: function (message) {
      if (message) {
        this.showAlertBox = true;
        this.alertBoxStyle.background = this.getAlertColor;

        setTimeout(() => {
          this.showAlertBox = false;
          this.setAlertMessage('');
        }, this.getAlertTime);
      }
    }
  },
  methods: {
    ...mapMutations({
      setAlertMessage:    'Alert/setAlertMessage',
    }),
    hideAlertBox() {
      this.showAlertBox = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .alert-box {
    position: fixed;
    display: flex;
    top: 85px;
    background: #22cf95;
    color: #fff;
    width: calc(1400px - 110px);
    margin-left: 25px;
    padding: 25px 30px 25px 30px;
    border-radius: 5px;
    font-size: 20px;
    z-index: 10;

    .message {
      width: calc(1290px - 60px);
    }

    .icon {
      width: 30px;
    }

    .right {
      text-align: right;

      i {
        cursor: pointer;
      }
    }
  }
</style>
