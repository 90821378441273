<template>
  <div class="burger-menu">
    <div class="veil" @click="hideMenu"/>
    <div class="wrapper">
      <div class="flex">
        <h1>Меню сайта</h1>
        <div>
          <router-link to="/" @click="hideMenu"><i class="fa fa-home"></i></router-link>
          <router-link to="/site_map" @click="hideMenu"><i class="fa fa-map"></i></router-link>
        </div>
      </div>
      <i class="fa fa-times close-button" @click="hideMenu"></i>
      <div class="flex">
        <div class="menu-parent-block">
          <SearchLine
              :closeBurger="true"
              @hideMenu="hideMenu"
          />
          <div class="main-menu">
            <MenuByTypes menu-type="articleMenu" :showTitles="true"/>
          </div>
          <footer>
            <MenuByTypes/>
          </footer>
        </div>
        <div class="media-parent-block">
          <a href="https://vk.com/keyntr" target="_blank">
            <img src="https://sun9-70.userapi.com/impf/c624329/v624329145/1a3c6/o3VoQRbGFjc.jpg?size=819x2048&quality=96&sign=d13f427252c4d393f4b6624db246996d&type=album">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuByTypes from "@/components/menu/MenuByTypes";
import SearchLine from "@/components/search/SearchLine";

export default {
  name: 'BurgerMenu',
  components: {SearchLine, MenuByTypes},
  data() {
    return {
    }
  },
  methods: {
    hideMenu() {
      this.$emit('hideMenu', false);
    },
  },
}
</script>

<style lang="scss" scoped>
.burger-menu {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 600;

  width: 100%;
  height: 100%;

  color: var(--second-text-color);

  a {
    i.fa {
      font-size: 27px;
      color: var(--second-text-color);
      margin-right: 1rem;
    }
  }

  .wrapper {
    position: relative;

    width: calc(var(--main-block-width) - 120px);
    height: calc(100% - 60px);

    margin: 0 auto 300px auto;

    padding: 30px 60px 30px 60px;

    background: var(--main-block-color);

    h1 {
      color: var(--second-text-color);
      margin: -3px 6rem 3rem 0;
    }

    .close-button {
      position: absolute;
      top: 0;
      right: 0;

      margin-top: 25px;
      margin-right: 60px;

      font-size: 35px;
      cursor: pointer;
    }

    .menu-parent-block {
      width: 80%;

      .main-menu {
        display: flex;
        height: 70%;
      }

      footer {
        display: flex;

        height: 30%;
      }

      .menu-block {
        width: 25%;

        span {
          &.menu-category-title {
            display: block;

            padding-bottom: 1rem;

            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }

        a {
          display: block;

          color: var(--second-href-color);
          font-size: 16px;

          margin-bottom: 10px;
        }
      }
    }

    .media-parent-block {
      width: 20%;

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .burger-menu {
    overflow: auto;

    .wrapper {
      width: calc(100% - 20px);
      padding: 15px 10px 30px 10px;

      .close-button {
        margin-top: 15px;
        margin-right: 15px;
      }

      .main-menu {
        display: block;
        height: unset;

        .menu-block {
          margin-bottom: 20px;
        }
      }

      .menu-block {
        width: 100%;
      }

      footer {
        display: block;

        .menu-block {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
