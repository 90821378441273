export default {
    namespaced: true,

    state: {
        alertMessage: '',
        alertTime: 0,
        alertColor: '',
    },

    getters: {
        getAlertMessage: state => state.alertMessage,
        getAlertTime: state => state.alertTime,
        getAlertColor: state => state.alertColor,
    },

    mutations: {
        setAlertMessage(state, value) {
            state.alertMessage = value;
        },
        setAlertTime(state, value) {
            state.alertTime = value;
        },
        setAlertColor(state, value) {
            state.alertColor = value;
        },
    },
}